import {
  CalendarDate,
  DateFormatter,
  getLocalTimeZone,
  parseDate,
  today,
} from '@internationalized/date'

const WEEK_LENGTH = 604800000

export function isTomorrow(date: string | CalendarDate) {
  const now = today(getLocalTimeZone())
  if (typeof date === 'string') {
    date = parseDate(date)
  }
  return now.compare(date) === 1
}

export function onCurrentWeek(date: Date) {
  if (!(date instanceof Date)) {
    throw new Error('Invalid argument: you must provide a "date" instance')
  }

  const lastMonday = new Date() // Creating new date object for today
  lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() - 1)) // Setting date to last monday
  lastMonday.setHours(0, 0, 0, 0) // Setting Hour to 00:00:00:00

  return (
    lastMonday.getTime() <= date.getTime() && date.getTime() < lastMonday.getTime() + WEEK_LENGTH
  )
}

export function toLocaleDate(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  const formatter = new DateFormatter('cs')
  return formatter.format(date)
}

export function toNiceTime(minutes: number) {
  let hours = minutes / 60
  const minutesInDisplay = minutes % 60
  if (hours > 23) {
    hours = 24
  }
  return `${Math.floor(hours)}:${minutesInDisplay === 0 ? '00' : minutesInDisplay}`
}

export const weekdays = computed(() => {
  const { locale } = useI18n()
  const formatter = new Intl.DateTimeFormat(locale.value.replace('_', '-'), { weekday: 'short' })
  const weekdays = []

  for (let day = 0; day < 7; day++) {
    const date = new Date(Date.UTC(2021, 7, 2 + day))
    const parts = formatter.formatToParts(date)
    const weekday = parts.find((part) => part.type === 'weekday')?.value
    weekdays.push(weekday)
  }

  return weekdays
})
